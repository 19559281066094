<template>
<div>
    <div style="display: flex; justify-content: space-between; align-items: center;">
        <div style="display: flex; gap: 16px; flex-grow: 1; align-items: center; ">
            <img src="https://d3vsnl8idgwrlw.cloudfront.net/BackButtonWhite.svg" alt="" style="width: 20px; height: 14px; cursor: pointer;" @click="goToOPDPage">
            <div class="headerHeading" v-if="showHospitalInfoLoader == false">{{ hospitalData.name }}</div>
        </div>
        <div>
            <img src="https://d3vsnl8idgwrlw.cloudfront.net/Details.svg" alt="" @click="toggleHospitalDetails" style="cursor: pointer;">
        </div>
    </div>
    <div v-if="showHospitalInfoLoader == false">
        <v-bottom-sheet  :persistent="true" v-model="viewDetailsModel" max-width="425px" >
            <v-sheet
            class="text-center"
            >
            <div class="viewDetailsBottomContainer">
                <div>
                    <div class="viewHospitalDetailsBottomSheetHeading">
                        <div style="font-size: 14px; font-weight: 500; color: #4f4f4f;">View Hospital</div>
                        <img src="https://d3vsnl8idgwrlw.cloudfront.net/closeCircular.svg"  @click="closeBottomSheet" style="cursor: pointer;"/>
                    </div>
                </div>
                <div class="viewDetailsBody">
                    <div class="hospitalNameContainer">
                        <div class="hospitalLogo">
                            <img :src="hospitalData.hospitalLogo" style="max-width: 100%; max-height: 100%; object-fit: cover; border-radius: 8px;"/>
                        </div>
                        <div class="hospitalName">
                            <div style="font-size: 16px; font-weight: 600; color: #333333; text-align: left;">{{ hospitalData.name}}</div>
                            <div style="font-size: 14px; font-weight: 500; color: #1467BF;">{{ hospitalLocation }}</div>
                        </div>
                    </div>
                    <div>
                        <div class="noOfDoctor">
                            {{ hospitalData.doctorCount }} + Doctors
                        </div>
                    </div>
                    <div style="display: flex; gap: 8px; margin-top: 24px; width: 100%;">
                        <div class="registrationNoContainer" style="flex: 1;"> 
                            <div class="registrationNumHeader">Reg No:</div>
                            <div class="registrationNumValue">{{ hospitalData.hospitalRegNumber }}</div>
                        </div>
                        <div class="registrationNoContainer" style="flex: 1;">
                            <div class="registrationNumHeader">Phone No:</div>
                            <div class="registrationNumValue">{{ hospitalData.phoneNumbers[0] }}</div>
                        </div>
                        <!-- <div class="registrationNoContainer">
                            <div class="registrationNumHeader">Reviews:</div>
                            <div class="registrationNumValue">{{ reviews }}</div>
                        </div> -->
                    </div>
                    <div class="hospitalAbout">
                        <div style="font-size: 16px; font-weight: 600; color: #2D264B;">About</div>
                        <div style="font-size: 14px; font-weight: 400; color: #7C8287; text-align: justify; line-height: 21px; text-align: left;" >{{ readMore ? fullDescription : textPreview }}
                        <div v-if="!readMore && textPreview != fullDescription" style="font-weight: 500; font-size: 14px; color: #48acef; text-align: left; cursor: pointer;" @click="() => {readMore=true;}">Read More</div>
                        </div>
                    </div>

                </div>
            </div>

        </v-sheet>
        </v-bottom-sheet>
    </div>
</div>
</template>

<script>



export default {
    name: 'ParticularHospitalHeaderComp',
    data: function() {
        return {
            todayAppointment: [],
            pageLoading: true,
            ongoingAppointment: [],
            bannerURL: '',
            viewDetailsModel: false,
            hospitalName: 'Asia Royal Hospital',
            hospitalLocation: 'Yangon',
            hospitalRegNumber: '1241424',
            phoneNumber: '+95 67980342453',
            reviews: '299',
            doctorCount: 56,
            // fullDescrip/tion: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolor, dolorem laborum. Corporis mollitia, aut, nihil animi sit adipisci vero voluptatem facere error hic at iure repellat cupiditate, officiis laborum sint. Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolor, dolorem laborum. Corporis mollitia, aut, nihil animi sit adipisci vero voluptatem facere error hic at iure repellat cupiditate, officiis laborum sint.',
            readMore: false,
        };
    },
    computed: {
        showHospitalInfoLoader () {
            return this.$store.state.particularHospitalState.fetchingHospitalInfo;        
        },
        hospitalData () {
            return this.$store.state.particularHospitalState.hospitalInfo;        
        },
        fullDescription() {
            return this.$store.state.particularHospitalState.hospitalInfo.about;        
        },  
        textPreview() {
            return this.fullDescription.slice(0, 1000);
        }
    },
    async mounted() {
    },
    methods: {
        goToOPDPage() {
            this.$router.push({
                name: 'OPDHome'
            })
        },
        toggleHospitalDetails() {
            this.viewDetailsModel = !this.viewDetailsModel;
        },
        closeBottomSheet() {
            this.viewDetailsModel = false;
        },
        descriptionPreview() {

        }
    },
};

</script>
<style  scoped>
.headerHeading {
    color: white;
    font-weight: 600;
}
.v-sheet {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
}

.v-dialog__content >>> .v-bottom-sheet {
    box-shadow: none !important;
}
.viewHospitalDetailsBottomSheetHeading {
    display: flex;
    justify-content: space-between;
    padding: 12px 16px 12px 16px;
    border-bottom: 1px solid #e8e8eb;
}
.viewDetailsBottomContainer {
    display: flex;
    flex-direction: column;
    height: 80vh;

}
.viewDetailsBody {
    padding: 20px 16px 20px 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 600px;
    overflow: auto;
    
}
.hospitalNameContainer {
    display: flex;
    gap: 8px;
}
.hospitalLogo {
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 56px;
    max-width: 56px;
    min-height: 56px;
    min-width: 56px;
    background: white;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
}
.hospitalName {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.hospitalAbout {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    margin-top: 24px;
}
.registrationNoContainer {
    display: flex;
    flex-direction: column;
    gap: 8px;
    border-radius: 8px;
    border: 1px solid #e0e0e0;
    padding: 8px 16px 8px 16px;
}
.registrationNumHeader{
    font-size: 14px;
    font-weight: 500;
    color: #4f4f4f;
}
.registrationNumValue{
    font-size: 14px;
    font-weight: 600;
    color: #333333;
}
.noOfDoctor {
    padding: 6px 12px 6px 12px;
    gap: 8px;
    border-radius: 50px;
    opacity: 0px; 
    font-size: 14px;
    font-weight: 600;
    color: #1467BF;
    background: #1467BF1A;
    margin-top: 24px;
}

</style>
